$icon_color: #4d555a;
$icon_hover: #dc3545;
$color_white: white;
$color_4: #6a6a6a;
$color_5: #8a8a8a;
$color_6: #ff5f57;
$cookie_light: #D9BE91;
$cookie_dark: #84563C;


$background-color_1: #111418;
$background-color_2: #E6ECF8;
$background-color_3: #555;
$background-color_4: #ddd;
$background-color_5: #ff5f57;
$background-color_6: #ffbd2e;
$background-color_7: #28ca41;
$background-color_8: white;
$background-color_9: rgba(33, 37, 41, 0.65);
$background-color_10: rgba(0, 0, 0, 0.5);
$primary-bg-color: #19ab6e;
$primary-bg-color-fade: rgba(32, 201, 150, 0.75);
$ribbon-bg-color:   rgba(32, 201, 150, 0.75);


::-webkit-scrollbar {
	width: 8px;
	border-left: 1px solid #E6ECF8;
}
::-webkit-scrollbar-thumb {
	background-color: $background-color_1;
}
.primary-menu {
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-track {
		background: #111418;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $background-color_2;
		border-radius: 0px;
		&:hover {
			background-color: $background-color_3;
		}
	}
}
.modal {
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-track {
		background: #111418;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $background-color_2;
		border-radius: 0px;
		&:hover {
			background-color: $background-color_3;
		}
	}
}
.icon--list {
	li {
		i {
			color: $icon_color;
			&:hover {
				color: $icon_hover;
				cursor: pointer;
			}
		}
		a {
			height: 20px;
			width: 20px;
			line-height: 20px;
			.badge {
				background: #19ab6e;
			}
			.badgei {
				color: $color_white;
			}
		}
	}
}
.bar {
	display: block;
	width: 100%;
}
.portfolio-box {
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  overflow: hidden;
	&:hover {
		cursor: pointer;
	}
	.bar {
		display: block;
		position: relative;
		height: 24px;
		background-color: $background-color_4;
		width: 100%;
		color: $color_4;
		z-index: 10;
		i {
			height: 12px;
			width: 12px;
			display: inline-block;
			background-color: $background-color_5;
			border-radius: 50%;
			position: absolute;
			top: 6px;
			left: 10px;
			&:after {
				height: 12px;
				width: 12px;
				display: inline-block;
				background-color: $background-color_5;
				border-radius: 50%;
				position: absolute;
				top: 6px;
				left: 10px;
				content: " ";
				left: 32px;
				background-color: $background-color_7;
				top: 0;
			}
			&:before {
				height: 12px;
				width: 12px;
				display: inline-block;
				background-color: $background-color_5;
				border-radius: 50%;
				position: absolute;
				top: 6px;
				left: 10px;
				content: " ";
				left: 16px;
				background-color: $background-color_6;
				top: 0;
			}
		}
		h2 {
			content: attr(data-text);
			text-align: center;
			font-size: 0.65rem;
			text-transform: uppercase;
			font-weight: 500;
			letter-spacing: 0.05rem;
			line-height: 26px;
			color: $color_5;
		}
		h3 {
			content: attr(data-text);
			text-align: center;
			font-size: 0.65rem;
			text-transform: uppercase;
			font-weight: 500;
			letter-spacing: 0.05rem;
			line-height: 26px;
			color: $color_5;
		}
	}
}
.project--modal {
	.bar {
		display: block;
		position: relative;
		height: 24px;
		background-color: $background-color_4;
		width: 100%;
		color: $color_4;
		z-index: 10;
		height: 34px;
		span {
			height: 12px;
			width: 12px;
			display: inline-block;
			background-color: $background-color_5;
			border-radius: 50%;
			position: absolute;
			top: 6px;
			left: 10px;
			top: 9px;
			background-color: $background-color_5;
			color: $color_6;
			font-size: 14px;
			line-height: 14px;
			transition: 0.2s;
			width: 14px;
			height: 14px;
			&:after {
				height: 12px;
				width: 12px;
				display: inline-block;
				background-color: $background-color_5;
				border-radius: 50%;
				position: absolute;
				top: 6px;
				left: 10px;
				content: " ";
				left: 32px;
				background-color: $background-color_7;
				top: 0;
				width: 14px;
				height: 14px;
				content: " ";
				left: 44px;
			}
			&:before {
				height: 12px;
				width: 12px;
				display: inline-block;
				background-color: $background-color_5;
				border-radius: 50%;
				position: absolute;
				top: 6px;
				left: 10px;
				content: " ";
				left: 16px;
				background-color: $background-color_6;
				top: 0;
				width: 14px;
				height: 14px;
				content: " ";
				left: 22px;
			}
			&:hover {
				background-color: $background-color_8;
				color: $color_6;
				cursor: pointer;
			}
		}
		h2 {
			content: attr(data-text);
			text-align: center;
			font-size: 0.65rem;
			text-transform: uppercase;
			font-weight: 500;
			letter-spacing: 0.05rem;
			line-height: 26px;
			color: $color_5;
			font-size: 12px;
			line-height: 34px;
			font-weight: 500;
			letter-spacing: 0.05rem;
		}
		h3 {
			content: attr(data-text);
			text-align: center;
			font-size: 0.65rem;
			text-transform: uppercase;
			font-weight: 500;
			letter-spacing: 0.05rem;
			line-height: 26px;
			color: $color_5;
		}
	}
}
.cookie-consent-container {
	.bar {
		display: block;
		position: relative;
		height: 24px;
		background-color: $background-color_4;
		width: 100%;
		color: $color_4;
		z-index: 10;
		i {
			height: 12px;
			width: 12px;
			display: inline-block;
			background-color: $background-color_5;
			border-radius: 50%;
			position: absolute;
			top: 6px;
			left: 10px;
			&:after {
				height: 12px;
				width: 12px;
				display: inline-block;
				background-color: $background-color_5;
				border-radius: 50%;
				position: absolute;
				top: 6px;
				left: 10px;
				content: " ";
				left: 32px;
				background-color: $background-color_7;
				top: 0;
			}
			&:before {
				height: 12px;
				width: 12px;
				display: inline-block;
				background-color: $background-color_5;
				border-radius: 50%;
				position: absolute;
				top: 6px;
				left: 10px;
				content: " ";
				left: 16px;
				background-color: $background-color_6;
				top: 0;
			}
		}
		h2 {
			content: attr(data-text);
			text-align: center;
			font-size: 0.65rem;
			text-transform: uppercase;
			font-weight: 500;
			letter-spacing: 0.05rem;
			line-height: 26px;
			color: $color_5;
		}
		h3 {
			content: attr(data-text);
			text-align: center;
			font-size: 0.65rem;
			text-transform: uppercase;
			font-weight: 500;
			letter-spacing: 0.05rem;
			line-height: 26px;
			color: $color_5;
		}
	}
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.project--close {
	background-size: 0.65em;
}
.project-details-modal {
	.bg-dark-1 {
		background-color: $background-color_9 !important;
	}
}
.nav-link {
	.social-icons {
		li {
			border-bottom: none;
		}
	}
}
.cookie-consent-content {
	position: relative;
}
.cookie-pop-bg {
	position: relative;
	left: 5px;
	>i {
		position: relative;
		font-size: 26px;
		color: $cookie_light;
	}
	>.fa-cookie-bite {
		left: -10px;
	}
	>.fa-cookie {
		color: $cookie_dark;
		opacity: 0.75;
	}
}
.slider-container {
	position: relative;
	flex: 1;
}
.expanded-image-container {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	// background-color: $background-color_10;
  text-align: center;
}
.expanded-image {
	max-width: 100%;
	height: auto;
}
.close {
	position: absolute;
	top: -8px;
	right: -8px;
	background-color: $background-color_10;
	text-align: center;
	color: $color_white;
	font-size: 18px;
	width: 44px;
	height: 44px;
	line-height: 42px;
	border-radius: 50%;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
	&:hover {
		background-color: $primary-bg-color;
		box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
	}
}
.maximize-project-image {
	position: absolute;
	opacity: 0;
	background-color: $background-color_10;
	text-align: center;
	color: $color_white;
	font-size: 18px;
	width: 44px;
	height: 44px;
	line-height: 42px;
	border-radius: 50%;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
	&:hover {
		background-color: $primary-bg-color-fade !important;
		box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
	}
}
.slider--image {
	transition: all 0.3s ease-in-out;
}
.item {
	overflow: hidden;
	position: relative;
	pointer-events: auto;
	&:hover {
		cursor: pointer;
		>.maximize-project-image {
			background-color: $primary-bg-color;
			top: 45%;
			left: 45%;
			opacity: 1;
		}
		>.content-overlay {
			opacity: 1;
		}
		>.slider--image-hover {
			filter: blur(2px);
			transform: scale(1.03);
		}
	}
}
.hide-details {
	display: none;
}
.fadeIn-bottom {
	top: 80%;
}
.content-overlay {
	background: rgba(0, 0, 0, 0.7);
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	opacity: 0;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.image-container {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	height: 100%;
}

.truncated-text {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}



.corner-ribbon {
  width: 200px;
  position: absolute;
  top: 0px;
  right: -25px;
  text-align: center;
  line-height: 35px;
  letter-spacing: 1px;
  color: $color_white;
  text-transform: uppercase;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  z-index: 999;
  font-size: .65rem;
  color: $icon_color;
  // background-color: rgba(245, 245, 245, 0.85);
  background-color: #ddd;
}


/* Custom styles */

.corner-ribbon.shadow {
  box-shadow: 0 0 3px rgba(0, 0, 0, .3);
}


/* Different positions */

.corner-ribbon.top-right {
  top: 35px;
  right: -50px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 200px;
  height: 35px;
}

.contact--mailto {
  &:hover {
    cursor: pointer;
  }
}
.slick-prev:before, .slick-next:before {
  color: black;
}
.slick-prev {
  left: 20px;
}
.slick-next {
  right: 20px;
}

.slick-prev, .slick-next {
  z-index: 1;
}


.hero-wrap {
  overflow: hidden;
  position: relative;
}

.hero-wrap .hero-bg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.hero-wrap .hero-bg .bg-img {
  position: absolute;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.hero-wrap .hero-mask {
  z-index: 1;
}

.slick-slider{
  touch-action: auto!important;
}

